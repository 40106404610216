import * as React from 'react'
import CanvasHouseViewer from '../../components/canvasHouseViewer/canvasHouseViewer'
import {
	getExampleLegacyHomeSpecId,
	getExamplePropertySpec,
	getExampleRenoworksId
} from '../../components/activeJobs/NoPropertiesYet'

const DemoProperty = () => (
	<>
		<CanvasHouseViewer
			renoworksId={getExampleRenoworksId()}
			legacyHomeSpecId={getExampleLegacyHomeSpecId()}
			propertySpec={getExamplePropertySpec()}
			newViewer={false}
			viewOnly={false}
		/>
	</>
)

export default DemoProperty